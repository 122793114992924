// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AbrTi3vIBILWGRWoTBRc{width:40px;grid-template-rows:0px 40px 0px;border-radius:40px;transform:translateY(-7px)}.er8fqFvstOuQFjz2SMgg{display:none}.tcFbIsIO46k4oBUBCKkw{transform:translateY(7px)}.iKBkFsptwK2mZY8_UAKg{border-radius:40px}`, "",{"version":3,"sources":["webpack://./../../../tools/nodeGeometryEditor/dist/graphSystem/display/elbowDisplayManager.modules.scss"],"names":[],"mappings":"AAAA,sBACI,UAAA,CACA,+BAAA,CACA,kBAAA,CACA,0BAAA,CAGJ,sBACI,YAAA,CAGJ,sBACI,yBAAA,CAGJ,sBACI,kBAAA","sourcesContent":[".elbowBlock {\r\n    width: 40px;\r\n    grid-template-rows: 0px 40px 0px;\r\n    border-radius: 40px;\r\n    transform: translateY(-7px);\r\n}\r\n\r\n.hidden {\r\n    display: none;\r\n}\r\n\r\n.translatedConnections {\r\n    transform: translateY(7px);\r\n}\r\n\r\n.roundSelectionBorder {\r\n    border-radius: 40px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"elbowBlock": `AbrTi3vIBILWGRWoTBRc`,
	"hidden": `er8fqFvstOuQFjz2SMgg`,
	"translatedConnections": `tcFbIsIO46k4oBUBCKkw`,
	"roundSelectionBorder": `iKBkFsptwK2mZY8_UAKg`
};
export default ___CSS_LOADER_EXPORT___;
